define("discourse/plugins/automation/admin/components/fields/da-email-group-user-field", ["exports", "@glimmer/tracking", "@ember/helper", "@ember/object", "select-kit/components/email-group-user-chooser", "discourse/plugins/automation/admin/components/fields/da-base-field", "discourse/plugins/automation/admin/components/fields/da-field-description", "discourse/plugins/automation/admin/components/fields/da-field-label", "@ember/component", "@ember/template-factory"], function (_exports, _tracking, _helper, _object, _emailGroupUserChooser, _daBaseField, _daFieldDescription, _daFieldLabel, _component, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class EmailGroupUserField extends _daBaseField.default {
    static #_ = (() => dt7948.g(this.prototype, "recipients", [_tracking.tracked]))();
    #recipients = (() => (dt7948.i(this, "recipients"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "groups", [_tracking.tracked], function () {
      return [];
    }))();
    #groups = (() => (dt7948.i(this, "groups"), void 0))();
    static #_3 = (() => (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <section class="field email-group-user-field">
          <div class="control-group">
            <DAFieldLabel @label={{@label}} @field={{@field}} />
    
            <div class="controls">
              <EmailGroupUserChooser
                @value={{@field.metadata.value}}
                @onChange={{this.mutValue}}
                @options={{hash
                  includeGroups=true
                  includeMessageableGroups=true
                  allowEmails=true
                  autoWrap=true
                  disabled=@field.isDisabled
                }}
              />
    
              <DAFieldDescription @description={{@description}} />
            </div>
          </div>
        </section>
      
    */
    {
      "id": "L8Bto5rk",
      "block": "[[[1,\"\\n    \"],[10,\"section\"],[14,0,\"field email-group-user-field\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"control-group\"],[12],[1,\"\\n        \"],[8,[32,0],null,[[\"@label\",\"@field\"],[[30,1],[30,2]]],null],[1,\"\\n\\n        \"],[10,0],[14,0,\"controls\"],[12],[1,\"\\n          \"],[8,[32,1],null,[[\"@value\",\"@onChange\",\"@options\"],[[30,2,[\"metadata\",\"value\"]],[30,0,[\"mutValue\"]],[28,[32,2],null,[[\"includeGroups\",\"includeMessageableGroups\",\"allowEmails\",\"autoWrap\",\"disabled\"],[true,true,true,true,[30,2,[\"isDisabled\"]]]]]]],null],[1,\"\\n\\n          \"],[8,[32,3],null,[[\"@description\"],[[30,3]]],null],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[\"@label\",\"@field\",\"@description\"],false,[]]",
      "moduleName": "/build/source/app/assets/javascripts/discourse/discourse/plugins/automation/admin/components/fields/da-email-group-user-field.js",
      "scope": () => [_daFieldLabel.default, _emailGroupUserChooser.default, _helper.hash, _daFieldDescription.default],
      "isStrictMode": true
    }), this))();
    updateRecipients(selected1, content1) {
      const newGroups1 = content1.filterBy("isGroup").mapBy("id");
      this._updateGroups(selected1, newGroups1);
      this.recipients = selected1.join(",");
    }
    static #_4 = (() => dt7948.n(this.prototype, "updateRecipients", [_object.action]))();
    _updateGroups(selected1, newGroups1) {
      const groups1 = [];
      this.groups.forEach(existing1 => {
        if (selected1.includes(existing1)) {
          groups1.addObject(existing1);
        }
      });
      newGroups1.forEach(newGroup1 => {
        if (!groups1.includes(newGroup1)) {
          groups1.addObject(newGroup1);
        }
      });
      this.groups = groups1;
    }
  }
  _exports.default = EmailGroupUserField;
});