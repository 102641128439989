define("discourse/plugins/automation/admin/components/fields/da-category-notification-level-field", ["exports", "@ember/helper", "select-kit/components/category-notifications-button", "discourse/plugins/automation/admin/components/fields/da-base-field", "discourse/plugins/automation/admin/components/fields/da-field-description", "discourse/plugins/automation/admin/components/fields/da-field-label", "@ember/component", "@ember/template-factory"], function (_exports, _helper, _categoryNotificationsButton, _daBaseField, _daFieldDescription, _daFieldLabel, _component, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class CategoryNotficationLevelField extends _daBaseField.default {
    static #_ = (() => (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <section class="field category-notification-level-field">
          <div class="control-group">
            <DAFieldLabel @label={{@label}} @field={{@field}} />
    
            <div class="controls">
              <CategoryNotificationsButton
                @value={{@field.metadata.value}}
                @onChange={{this.mutValue}}
                @options={{hash showFullTitle=true}}
              />
    
              <DAFieldDescription @description={{@description}} />
            </div>
          </div>
        </section>
      
    */
    {
      "id": "ZJmlZyia",
      "block": "[[[1,\"\\n    \"],[10,\"section\"],[14,0,\"field category-notification-level-field\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"control-group\"],[12],[1,\"\\n        \"],[8,[32,0],null,[[\"@label\",\"@field\"],[[30,1],[30,2]]],null],[1,\"\\n\\n        \"],[10,0],[14,0,\"controls\"],[12],[1,\"\\n          \"],[8,[32,1],null,[[\"@value\",\"@onChange\",\"@options\"],[[30,2,[\"metadata\",\"value\"]],[30,0,[\"mutValue\"]],[28,[32,2],null,[[\"showFullTitle\"],[true]]]]],null],[1,\"\\n\\n          \"],[8,[32,3],null,[[\"@description\"],[[30,3]]],null],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[\"@label\",\"@field\",\"@description\"],false,[]]",
      "moduleName": "/build/source/app/assets/javascripts/discourse/discourse/plugins/automation/admin/components/fields/da-category-notification-level-field.js",
      "scope": () => [_daFieldLabel.default, _categoryNotificationsButton.default, _helper.hash, _daFieldDescription.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = CategoryNotficationLevelField;
});