define("discourse/plugins/automation/admin/components/fields/da-field-description", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const FieldDescription = (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
    {{#if @description}}
      <p class="control-description">
        {{@description}}
      </p>
    {{/if}}
  
  */
  {
    "id": "hAf/QY7i",
    "block": "[[[1,\"\\n\"],[41,[30,1],[[[1,\"    \"],[10,2],[14,0,\"control-description\"],[12],[1,\"\\n      \"],[1,[30,1]],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null]],[\"@description\"],false,[\"if\"]]",
    "moduleName": "/build/source/app/assets/javascripts/discourse/discourse/plugins/automation/admin/components/fields/da-field-description.js",
    "isStrictMode": true
  }), (0, _templateOnly.default)(undefined, "da-field-description:FieldDescription"));
  var _default = _exports.default = FieldDescription;
});