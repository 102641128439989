define("discourse/plugins/automation/admin/components/fields/da-custom-fields", ["exports", "@glimmer/tracking", "@ember/helper", "@ember/render-modifiers/modifiers/did-insert", "@ember/service", "discourse-common/utils/decorators", "select-kit/components/multi-select", "discourse/plugins/automation/admin/components/fields/da-base-field", "discourse/plugins/automation/admin/components/fields/da-field-description", "discourse/plugins/automation/admin/components/fields/da-field-label", "@ember/component", "@ember/template-factory"], function (_exports, _tracking, _helper, _didInsert, _service, _decorators, _multiSelect, _daBaseField, _daFieldDescription, _daFieldLabel, _component, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class GroupField extends _daBaseField.default {
    static #_ = (() => dt7948.g(this.prototype, "store", [_service.service]))();
    #store = (() => (dt7948.i(this, "store"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "allCustomFields", [_tracking.tracked], function () {
      return [];
    }))();
    #allCustomFields = (() => (dt7948.i(this, "allCustomFields"), void 0))();
    static #_3 = (() => (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <section class="field group-field" {{didInsert this.loadUserFields}}>
          <div class="control-group">
            <DAFieldLabel @label={{@label}} @field={{@field}} />
    
            <div class="controls">
              <MultiSelect
                @value={{@field.metadata.value}}
                @content={{this.allCustomFields}}
                @onChange={{this.mutValue}}
                @nameProperty={{null}}
                @valueProperty={{null}}
                @options={{hash allowAny=false disabled=@field.isDisabled}}
              />
              <DAFieldDescription @description={{@description}} />
            </div>
          </div>
        </section>
      
    */
    {
      "id": "Pbb6aMge",
      "block": "[[[1,\"\\n    \"],[11,\"section\"],[24,0,\"field group-field\"],[4,[32,0],[[30,0,[\"loadUserFields\"]]],null],[12],[1,\"\\n      \"],[10,0],[14,0,\"control-group\"],[12],[1,\"\\n        \"],[8,[32,1],null,[[\"@label\",\"@field\"],[[30,1],[30,2]]],null],[1,\"\\n\\n        \"],[10,0],[14,0,\"controls\"],[12],[1,\"\\n          \"],[8,[32,2],null,[[\"@value\",\"@content\",\"@onChange\",\"@nameProperty\",\"@valueProperty\",\"@options\"],[[30,2,[\"metadata\",\"value\"]],[30,0,[\"allCustomFields\"]],[30,0,[\"mutValue\"]],null,null,[28,[32,3],null,[[\"allowAny\",\"disabled\"],[false,[30,2,[\"isDisabled\"]]]]]]],null],[1,\"\\n          \"],[8,[32,4],null,[[\"@description\"],[[30,3]]],null],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[\"@label\",\"@field\",\"@description\"],false,[]]",
      "moduleName": "/build/source/app/assets/javascripts/discourse/discourse/plugins/automation/admin/components/fields/da-custom-fields.js",
      "scope": () => [_didInsert.default, _daFieldLabel.default, _multiSelect.default, _helper.hash, _daFieldDescription.default],
      "isStrictMode": true
    }), this))();
    loadUserFields() {
      this.store.findAll("user-field").then(fields1 => {
        this.allCustomFields = fields1.content.map(field1 => field1.name);
      });
    }
    static #_4 = (() => dt7948.n(this.prototype, "loadUserFields", [_decorators.bind]))();
  }
  _exports.default = GroupField;
});